<template>
  <div class="container-fluid bg-light py-3">
    <main class="container-xl">
      <h1>Servicios de telefonía IP y colaboración</h1>
            <span>
                <router-link class="btn" to="/servicios/gotoconnect">
                    <img src="../assets/LMI_GoToConnect_Green_HEX.png" alt="/" height="80">
                </router-link>
                <router-link class="btn" to="/servicios/gotomeeting">
                    <img src="../assets/LMI_GoToMeeting_Orange_HEX.png" alt="/" height="80">
                </router-link>
                <router-link class="btn" to="/servicios/gotowebinar">
                    <img src="../assets/LMI_GoToWebinar_Blue_HEX.png" alt="/" height="80">
                </router-link>
                <router-link class="btn" to="/servicios/logmeinrescue">
                    <img src="../assets/LMI_Rescue_Blue_HEX.png" alt="/" height="80">
                </router-link>
                <router-link class="btn" to="/servicios/logmeinpro">
                    <img src="../assets/LMI_Pro.png" alt="/" height="80">
                </router-link>
            </span>
    </main>
  </div>
</template>
<script>
export default {
  name: 'LMIGeneral'
}
</script>